<template>
	<v-dialog
			max-width="600"
			:retain-focus="false"
			fullscreen
			style="overflow-x: hidden; background-color: #fff;"
			v-model="dialog"
			persistent
			transition="dialog-bottom-transition"
			scrollable
	>
		<v-card tile>
			<v-toolbar
					style="flex: 0 1 auto;"
					height="60px"
					flat
					dark
					color="primary"
			>
				<v-btn icon
						dark
						@click="onClose">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>
					Static page: {{ currentItem ? 'Edit' : 'Create' }}
				</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn @click.prevent="onClose()"
						color="silver"
						class="mr-1"
				>
					{{ $t('close') }}
				</v-btn>
			</v-toolbar>
			<v-card class="pt-0">
				<v-progress-linear
						v-show="loadingSaveItem"
						absolute
						top
						indeterminate
						color="primary"
				></v-progress-linear>
				<v-form
						ref="form"
						class="pa-5 pt-0"
						@submit="onSubmit"
						v-model="valid">
					<v-row>
						<v-col cols="12" sm="6">
							<v-text-field
								label="Name"
								required
								v-model="payload.name"
								:rules="defaultRules"
							></v-text-field>
						</v-col>
						<v-col cols="12" sm="6">
							<v-text-field
								label="Key"
								required
								v-model="payload.slug"
								:rules="defaultRules"
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6">
							<v-text-field
									label="Position"
									type="number"
									v-model="payload.position"
									:rules="defaultRules"
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<main-editor
									v-model="payload.description"
									label="Description"
							></main-editor>
						</v-col>

					</v-row>


					<v-row class="">
						<v-col cols="12">
							<v-btn type="submit"
									min-width="150"
									color="success"
									:loading="loadingSaveItem"
									:disabled="loadingSaveItem"
									@click.prevent="onSubmit"
							>
								{{ $t('save') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</v-card>
		</v-card>
	</v-dialog>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import validation from '@/mixins/validation';

	import MainEditor from '@/components/editor/index.vue'

	export default {
		name: 'page-save',
		mixin: [validation],
		components: {
			MainEditor
		},
		props: ['currentItem'],
		data(){
			return {
				dialog: false,
				valid: false,

				payload: {
					name: '',
					status_id: 2,
					country_id: '',
					position: '',
					slug: '',
					description: '',
					// title: {
					// 	uk: 'Title1'
					// },
					// content:{
					// 	uk: 'Content'
					// }
					en:{
						title: 'Title 1',
						content: 'Contebnt'
					},
					ru:{
						title: 'Title 1',
						content: 'Contebnt'
					},
					uk:{
						title: 'Title 1',
						content: 'Contebnt'
					}
				}
			}
		},

		watch: {
			currentItem () {
				if (this.currentItem) {
					this.setData()
				}
			}
		},

		computed:{
			...mapState({
				loadingSaveItem: state => state.administrationsPages.loadingSaveItem,
			}),
		},

		created(){
			if (this.currentItem) {
				this.setData()
			}

			this.$on('event-change-dialog', status => {
				if (this.currentItem) this.setData();
				this.dialog = status;

			})
		},

		methods:{
			...mapActions({
				SAVE_ITEM: 'administrationsPages/SAVE_ITEM',
			}),
			onSubmit(){
				this.$refs.form.validate();

				if (this.valid) {
					this.SAVE_ITEM(this.payload).then(() => {
						this.$toasted.success('Operation success');
					})
				}

			},

			setData () {
				Object.assign(this.payload, this.currentItem)
			},

			onClose(){
				this.dialog = false;
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
